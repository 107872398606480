// english

export const pack = {

     // dashboard
     dashboardTitle : "SMARTLOCKER",
     menu_dashboardTitle : "SLCMS - Dashboard",

     // basic notification
     notification_onSaveDone : "Information Saved",
     notification_onRemoveDone : "Removed Successfully",
     
     // confirm form
     confirmForm_onRemoveTitle : "Remove?",
     confirmForm_onRemoveButton : "Remove",

     confirmForm_onProceedTitle : "Proceed?",
     confirmForm_onProceedButton : "Proceed",

     // auth ...
     form_login_title : "Login to your account",
     form_login_subtitle : "Your credential",

     form_login_button : "LOGIN",
     form_login_terms : "By continuing, you're confirming that you've read our <a href='#'>Terms &amp; Conditions</a> and <a href='#'>Cookie Policy</a>",

     user_editing_title : "Editing User Details",
     form_editing_title : "Editing Details",

     event_auth_rejected_topic : "Authenication Rejected",
     event_auth_rejected_body : "Your request has been rejected from other session",

     event_user_revoke_topic : "Account was removed",
     event_user_revoke_body : "Your account has been removed for security reason.",

     event_kickout_topic : "You have been logout",
     event_kickout_body  : "Reason",

     event_checkin_timeout_topic : "Check-in Timeout",
     event_checkin_timeout_body : "Cannot register your session to authentication server within a time frame. You may try again later or contact system administrator for help.",

     event_grantaccess_message : "Grant access from",
     event_grantaccess_title : "Authorization Request",
     event_grantaccess_approve_button : "Approve",
     event_grantaccess_reject_button : "Reject",
     event_grantaccess_ignore_button : "ignore",

};
