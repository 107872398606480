
import * as layouts from "../../js/layoutTemplates.js";       // stores all kind of layout format

export const page_manifest = {
     title : "Lockers Management",
     layout : layouts.base,             // layout type, in layoutTemplate.js
     contents : false                   //  how to render layout 
                                        //   function(verb, e) - inline
                                        //   EXTERNAL_URL - route to url by pop up window
                                        //   false - nothing happen ( load verbs/index.html)
};

//   all verb mapping to CRUD
export const verbMapper = {  
     list : "lockerList",
     create : "lockerCreate",
     edit : "lockerEdit",
     update : "lockerSave",
     remove : "lockerRemove",
     sync : "lockerSyncSettings"     
}


// form input configurate
//   ie. { 'pj-form-data payload name' : {
//             readonly : bool (readonly)
//             value : (default value)
//             touch : (add pj-form-updated whilt initiate)
//             disabled : bool (disable input)
//     }

// for form edit
export const cfg_edit = {
     orderno : {
          disabled : true
     }
};

// for form create
export const cfg_create = {
     orderno : {
          disabled : true
     },
     status : {
          value : 10
     }
};

//   rev 23.03.22
// for table header
//   structure :
//        quickMenu : {
//             [payload] : { label, icon, verb, bulk}
//        }
//
export const cfg_tableheader = {
     quickMenu : [          
          {
               action : "activate",
               label : "Enable",
               icon : "icon-checkmark3 text-success",
               verb : verbMapper.update,
               bulk : true
          },
          {
               action : "deactivate",
               label : "Disable",
               icon : "icon-cross2 text-danger",
               verb : verbMapper.update,
               bulk : true
          }
     ]
}


//  for local language
export const lang = {
     en : {
          form_edit_title : "Editing",
          form_slotview_title : "Slots Overview"
     },
     tc : {

     }
}
