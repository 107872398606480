
import {version} from '../../package.json';

const ws_url        = "wss://sg.sysnec.com:54080/slcms/v3";
const request_url  = "https://slcms.majorlink.sysnec.com";

const DEBUG_MODE = true;

let appVersion = version;
let appEnv     = process.env.NODE_ENV;

export  {
     DEBUG_MODE,
     ws_url as wsURL,
     request_url as requestURL,
     appVersion, appEnv

}

