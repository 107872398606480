{
    "name": "pjio-boilerplate",
    "version": "1.0.2",
    "description": "Boiler-plate for pjio-3",
    "source": [
        "src/index.html"
    ],
    "keywords": [],
    "author": "Deestnixon",
    "license": "ISC",
    "scripts": {
        "clean": "del dist\\*.* /Q ",
        "clean-out": "del out\\*.* /Q ",
        "clean-all": "del out\\*.* dist\\*.* /Q",
        "start": "parcel src/index.html src/verbs/*/*.html -p 8888 --no-cache --https",
        "build": "parcel build src/*.html src/verbs/*/index.html --public-url ./ --no-scope-hoist"
    },
    "staticFiles": {
        "staticPath": [
            {
                "staticPath": "src/assets",
                "staticOutDir": "assets"
            }
        ]
    },
    "dependencies": {
        "@shopify/draggable": "^1.0.0-beta.8",
        "@widgetjs/tree": "^1.8.3",
        "bs-custom-file-input": "^1.3.4",
        "crypto-es": "^2.1.0",
        "js-treeview": "^1.1.5",
        "jsonwebtoken": "~8.5.0",
        "ladda": "^2.0.3",
        "lodash": "^4.17.21",
        "moment": "^2.29.4",
        "parcel": "^2.8.3",
        "qrcode": "^1.5.3",
        "spin.js": "^4.1.1",
        "sweetalert2": "^11.7.3",
        "typeahead-standalone": "^4.22.0"
    },
    "devDependencies": {
        "@parcel/resolver-glob": "^2.8.3",
        "@parcel/transformer-inline-string": "^2.8.3",
        "@parcel/transformer-sass": "^2.8.3",
        "buffer": "^5.7.1",
        "crypto-browserify": "^3.12.0",
        "events": "^3.3.0",
        "html5-qrcode": "^2.3.8",
        "parcel-resolver-ignore": "^2.1.3",
        "process": "^0.11.10",
        "stream-browserify": "^3.0.0",
        "util": "^0.12.5"
    },
    "parcelIgnore": [
        "global_assets/*.*"
    ]
}
